.ant-layout {
  min-width: 0;
  background: transparent;
}

.ant-layout-sider-children {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.ant-layout-header {
  padding: 0 !important;
  line-height: normal;
  height: auto;
  background: transparent;
}

.ant-layout-footer {
  padding: 0 !important;
  background: transparent;
}

.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: initial !important;
}

.ant-tabs-nav .ant-tabs-tab {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 1.33rem !important;
}
.ant-tabs-nav .ant-tabs-tab:last-child, .ant-tabs-nav .ant-tabs-tab:last-of-type {
  margin-right: 0 !important;
}

.vb-tabs,
.vb-tabs-bold,
.vb-tabs-pills {
  overflow: visible !important;
}
.vb-tabs .ant-tabs-top-bar,
.vb-tabs-bold .ant-tabs-top-bar,
.vb-tabs-pills .ant-tabs-top-bar {
  margin-bottom: 0;
  border-bottom: none;
  height: 100%;
}
.vb-tabs .ant-tabs-nav-container,
.vb-tabs .ant-tabs-nav-wrap,
.vb-tabs .ant-tabs-nav-scroll,
.vb-tabs-bold .ant-tabs-nav-container,
.vb-tabs-bold .ant-tabs-nav-wrap,
.vb-tabs-bold .ant-tabs-nav-scroll,
.vb-tabs-pills .ant-tabs-nav-container,
.vb-tabs-pills .ant-tabs-nav-wrap,
.vb-tabs-pills .ant-tabs-nav-scroll {
  height: 100%;
  overflow: visible !important;
}
.vb-tabs .ant-tabs-nav,
.vb-tabs-bold .ant-tabs-nav,
.vb-tabs-pills .ant-tabs-nav {
  height: 100%;
}
.vb-tabs .ant-tabs-nav:before,
.vb-tabs-bold .ant-tabs-nav:before,
.vb-tabs-pills .ant-tabs-nav:before {
  bottom: -1px !important;
}
.vb-tabs .ant-tabs-nav > div,
.vb-tabs-bold .ant-tabs-nav > div,
.vb-tabs-pills .ant-tabs-nav > div {
  height: 100%;
}
.vb-tabs .ant-tabs-nav .ant-tabs-ink-bar,
.vb-tabs-bold .ant-tabs-nav .ant-tabs-ink-bar,
.vb-tabs-pills .ant-tabs-nav .ant-tabs-ink-bar {
  height: 1px;
  bottom: -1px !important;
}
.vb-tabs .ant-tabs-nav .ant-tabs-ink-bar[style*="width: 0px;"],
.vb-tabs-bold .ant-tabs-nav .ant-tabs-ink-bar[style*="width: 0px;"],
.vb-tabs-pills .ant-tabs-nav .ant-tabs-ink-bar[style*="width: 0px;"] {
  width: 100% !important;
}
.vb-tabs .ant-tabs-tab-active .nav-link,
.vb-tabs-bold .ant-tabs-tab-active .nav-link,
.vb-tabs-pills .ant-tabs-tab-active .nav-link {
  background-color: var(--vb-color-primary);
  color: #fff;
  border-radius: 0.25rem;
}

.vb-tabs-bold .ant-tabs-nav .ant-tabs-ink-bar {
  height: 7px !important;
  bottom: -4px !important;
  border-radius: 7px !important;
}

.vb-tabs-pills .ant-tabs-ink-bar {
  display: none !important;
}
.vb-tabs-pills .ant-tabs-nav .ant-tabs-tab {
  padding-left: 0;
  padding-right: 0;
}

.vb-tabs-bordered .ant-tabs-nav-list {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.vb-tabs-bordered.vb-tabs-bold .ant-tabs-top-bar {
  border-bottom: 1px solid #e4e9f0;
}

[data-vb-theme=dark] .ant-tabs .ant-tabs-card-bar .ant-tabs-tab {
  background: transparent;
}
[data-vb-theme=dark] .ant-tabs .ant-tabs-card-bar .ant-tabs-tab-active {
  background: #232135;
}

.ant-notification {
  max-width: calc(100vw - 48px);
}

[data-vb-theme=dark] .ant-notification-notice-message {
  color: #fff;
}
[data-vb-theme=dark] .ant-notification-close-icon svg {
  fill: #fff;
}

[data-vb-theme=dark] .ant-checkbox-inner {
  background-color: #5f6294;
}
[data-vb-theme=dark] .ant-checkbox-checked .ant-checkbox-inner {
  background: var(--vb-color-primary);
}
[data-vb-theme=dark] .ant-checkbox-disabled .ant-checkbox-inner {
  border-color: #232135 !important;
  opacity: 0.3;
}

[data-vb-theme=dark] .ant-tag {
  border-color: transparent;
}

.ant-dropdown-menu-item-group-list {
  margin: 0 !important;
  list-style: none !important;
  padding: 0 !important;
}

[data-vb-theme=dark] .ant-dropdown-menu-item-group-title {
  color: #7575a3 !important;
}
[data-vb-theme=dark] .ant-select-dropdown-menu-item-selected {
  color: #5f6294;
  background: #7275a5;
}

[data-vb-theme=dark] .ant-radio-disabled .ant-radio-inner {
  border-color: #232135 !important;
}
[data-vb-theme=dark] .ant-radio-group-solid .ant-radio-button-wrapper {
  color: #5f6294;
}
[data-vb-theme=dark] .ant-radio-group-solid .ant-radio-button-wrapper-checked {
  color: #fff;
}
[data-vb-theme=dark] .ant-radio-group-solid .ant-radio-button-wrapper-checked:hover, [data-vb-theme=dark] .ant-radio-group-solid .ant-radio-button-wrapper-checked:active {
  color: #fff;
}

.ant-btn:hover, .ant-btn:active, .ant-btn:focus {
  border-color: #42baf9;
  color: #42baf9;
}
.ant-btn-primary {
  background: var(--vb-color-primary) !important;
  border-color: var(--vb-color-primary) !important;
}
.ant-btn-primary:hover, .ant-btn-primary:active, .ant-btn-primary:focus {
  background: #42baf9 !important;
  border-color: #42baf9 !important;
  color: #fff;
}
.ant-btn-danger:hover, .ant-btn-danger:active, .ant-btn-danger:focus {
  background: #f63a45;
  border-color: #f63a45;
  color: #fff;
}
.ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-danger[disabled], .ant-btn-danger[disabled]:hover {
  background: #e4e9f0 !important;
  border-color: #e4e9f0 !important;
}

[data-vb-theme=dark] .ant-btn-primary:hover, [data-vb-theme=dark] .ant-btn-primary:active, [data-vb-theme=dark] .ant-btn-primary:focus {
  background: #42baf9 !important;
  border-color: #42baf9 !important;
  color: #fff;
}
[data-vb-theme=dark] .ant-btn-danger:hover, [data-vb-theme=dark] .ant-btn-danger:active, [data-vb-theme=dark] .ant-btn-danger:focus {
  background: #f7535c;
  border-color: #f7535c;
  color: #fff;
}
[data-vb-theme=dark] .ant-btn-primary[disabled], [data-vb-theme=dark] .ant-btn-primary[disabled]:hover, [data-vb-theme=dark] .ant-btn-danger[disabled], [data-vb-theme=dark] .ant-btn-danger[disabled]:hover {
  background: #1d1b2c !important;
  border-color: #232135 !important;
}

[data-vb-theme=dark] .ant-empty svg * {
  fill: #a1a1c2;
  stroke: #5f6294;
}

.ant-select-open .ant-select-selection {
  border-color: var(--vb-color-primary);
}
.ant-select-selection:hover {
  border-color: var(--vb-color-primary);
}

[data-vb-theme=dark] .ant-select-multiple .ant-select-selection-item {
  color: #aeaee0;
  background: var(--vb-color-primary);
}
[data-vb-theme=dark] .ant-select-multiple .ant-select-selection-item-remove {
  color: #aeaee0;
}
[data-vb-theme=dark] .ant-select-item-option-active:not(.ant-select-item-option-disabled), [data-vb-theme=dark] .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #aeaee0;
  background: var(--vb-color-primary);
}
[data-vb-theme=dark] .ant-select-selection--multiple .ant-select-selection__choice {
  color: #aeaee0;
  background: var(--vb-color-primary);
}
[data-vb-theme=dark] .ant-select-selection--multiple .ant-select-selection__choice-remove {
  color: #4f4f7a;
}
[data-vb-theme=dark] .ant-select-dropdown-menu-item-selected {
  color: #aeaee0;
  background: var(--vb-color-primary);
}
[data-vb-theme=dark] .ant-select-tree-dropdown .ant-select-dropdown-search .ant-select-search__field {
  background: #141322;
}

.ant-modal-mask {
  background: rgba(20, 19, 34, 0.7) !important;
}
.ant-modal-header {
  border-color: transparent;
}
.ant-modal-footer {
  border-color: transparent;
}
.ant-modal-content {
  box-shadow: 0 0 100px -30px rgba(57, 55, 73, 0.3);
}

[data-vb-theme=dark] .ant-modal-mask {
  background: rgba(20, 19, 34, 0.84);
}
[data-vb-theme=dark] .ant-modal-close-x {
  color: #a1a1c2;
}
[data-vb-theme=dark] .ant-modal-close-x:hover {
  color: #d9dee9;
}
[data-vb-theme=dark] .ant-modal-content {
  background: #131020;
}
[data-vb-theme=dark] .ant-modal-header {
  background: #131020;
  border-color: #131020;
}
[data-vb-theme=dark] .ant-modal-footer {
  border-color: #131020;
}

.ant-fullcalendar-content ul {
  padding-left: 0;
}

.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-month,
.ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-date {
  background: #f2f4f8;
}

[data-vb-theme=dark] .ant-calendar-range-picker-separator, [data-vb-theme=dark] .ant-calendar-range-middle {
  color: #a1a1c2;
}
[data-vb-theme=dark] .ant-calendar-active-week {
  background: #141322 !important;
}
[data-vb-theme=dark] .ant-calendar-active-week:hover {
  background: #141322 !important;
}
[data-vb-theme=dark] .ant-calendar-week-number .ant-calendar-body tr:hover {
  background: #141322 !important;
}
[data-vb-theme=dark] .ant-fullcalendar-selected-day .ant-fullcalendar-date {
  background: #141322;
}

[data-vb-theme=dark] .ant-progress-inner {
  background: #232135;
}

[data-vb-theme=dark] .ant-upload {
  background: #232135;
}
[data-vb-theme=dark] .ant-upload.ant-upload-drag {
  background: #232135;
}
[data-vb-theme=dark] .ant-upload .ant-upload-text {
  color: #aeaee0 !important;
}
[data-vb-theme=dark] .ant-upload .ant-upload-hint {
  color: #4f4f7a !important;
}

[data-vb-theme=dark] .ant-slider-rail {
  background: #232135;
}
[data-vb-theme=dark] .ant-slider-track {
  background: var(--vb-color-primary);
}
[data-vb-theme=dark] .ant-slider-dot {
  border-color: #4f4f7a;
}
[data-vb-theme=dark] .ant-slider-dot-active {
  border-color: var(--vb-color-primary);
}
[data-vb-theme=dark] .ant-slider:hover .ant-slider-rail {
  background: #4f4f7a;
}

.ant-collapse {
  background: #f2f4f8 !important;
}
.ant-collapse-content {
  background: none;
}
.ant-collapse.ant-collapse-borderless .ant-collapse-item:last-child {
  border-bottom: 0 !important;
}
.ant-collapse.ant-collapse-borderless .ant-collapse-content {
  background: #fff !important;
}

[data-vb-theme=dark] .ant-collapse {
  background: transparent !important;
}
[data-vb-theme=dark] .ant-collapse-content {
  background: none;
}
[data-vb-theme=dark] .ant-collapse.ant-collapse-borderless .ant-collapse-content {
  background: transparent !important;
}
[data-vb-theme=dark] .ant-collapse-header {
  background: #141322;
  color: #aeaee0 !important;
}

[data-vb-theme=dark] .ant-card {
  color: #aeaee0;
}
[data-vb-theme=dark] .ant-card-meta-description {
  color: #aeaee0;
}
[data-vb-theme=dark] .ant-card-actions {
  background: #0c0c1b;
}
[data-vb-theme=dark] .ant-card-actions > li .anticon {
  color: #aeaee0;
}

[data-vb-theme=dark] .ant-cascader-menu-item-active {
  background: var(--vb-color-primary);
}
[data-vb-theme=dark] .ant-cascader-menu-item-active:hover {
  background: var(--vb-color-primary);
}

.ant-input:hover, .ant-input:focus, .ant-input:active {
  border-color: var(--vb-color-primary);
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled), .ant-input-affix-wrapper:focus .ant-input:not(.ant-input-disabled), .ant-input-affix-wrapper:active .ant-input:not(.ant-input-disabled) {
  border-color: var(--vb-color-primary);
}

[data-vb-theme=dark] .ant-input-number-handler-wrap {
  background: #4f4f7a;
}

[data-vb-theme=dark] .ant-mentions textarea {
  background: #232135;
}

[data-vb-theme=dark] .ant-time-picker-panel-input {
  background: transparent;
}
[data-vb-theme=dark] .ant-time-picker-panel-select-option-selected {
  background: var(--vb-color-primary);
}
[data-vb-theme=dark] .ant-time-picker-panel-select-option-selected:hover {
  background: var(--vb-color-primary);
}

[data-vb-theme=dark] .ant-skeleton {
  opacity: 0.1;
}

[data-vb-theme=dark] .ant-breadcrumb-link, [data-vb-theme=dark] .ant-breadcrumb-link a {
  color: #aeaee0;
}
[data-vb-theme=dark] .ant-breadcrumb-separator {
  color: #4f4f7a;
}

[data-vb-theme=dark] .ant-menu-item-group-title {
  color: #7575a3;
}
[data-vb-theme=dark] .ant-menu-item-disabled {
  color: #4f4f7a !important;
}

.ant-table {
  background: transparent !important;
}

[data-vb-theme=dark] .ant-table-column-has-sorters:hover {
  background: #141322 !important;
}
[data-vb-theme=dark] .ant-table-column-sort {
  background: #141322 !important;
}