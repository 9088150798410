// Import mixins
@import "src/mixins.scss";

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-vb-theme="dark"] {
  .ant-cascader {
    &-menu-item-active {
      background: $primary;

      &:hover {
        background: $primary;
      }
    }
  }
}
