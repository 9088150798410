@import "src/mixins.scss";

/////////////////////////////////////////////////////////////////////////////////////////
/* PROGRESS */

// dark theme
[data-vb-theme="dark"] {
  .progress {
    background: $dark-gray-4;
  }
}
