@import "src/mixins.scss";

/////////////////////////////////////////////////////////////////////////////////////////
/* BUTTONS */

.btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  > .btn-group {
    float: none;
    display: table-cell;
    width: 1%;
    > .btn {
      width: 100%;
    }
  }
  &.btn-group-vertical {
    display: block;
    > .btn-group {
      display: block;
      width: 100%;
    }
    > label {
      margin-bottom: 0;
    }
  }
}

label.btn {
  input {
    display: none;
  }
}

.btn {
  outline: none !important;
  transition: color 0.2s ease-in-out, background 0.2s ease-in-out,
    border 0.2s ease-in-out;
  border-radius: 8px;
  &:hover,
  &:active {
    transition: color 0.1s ease-in-out, background 0.1s ease-in-out,
      border 0.1s ease-in-out;
  }
  &.btn-rounded {
    border-radius: 100px;
  }
  &.btn-squared {
    border-radius: 0;
  }
  &.btn-link {
    color: $primary;
    border-color: transparent !important;
    background: none !important;
    text-decoration: none;
    &:hover,
    &:active,
    &:focus,
    &.active {
      color: $text;
    }
  }
}

.btn-with-addon {
  overflow: hidden;
  position: relative;
  padding-left: rem(50) !important;
  border: none;

  .btn-addon {
    position: absolute;
    z-index: 1;
    top: -1px;
    left: -1px;
    bottom: -1px;
    background-color: rgba($white, 0.2);
    width: rem(40);
  }

  .btn-addon-icon {
    font-size: rem(16);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.btn,
.show > .btn {
  color: $text;
  background-color: $white;
  border-color: $border;
  &:hover,
  &:active {
    background-color: $gray-2;
    border-color: $gray-2;
  }
  &:hover:active,
  &:focus,
  &.active {
    background-color: $gray-2;
    border-color: $gray-2;
  }


  &.btn-primary,
  &.btn-secondary,
  &.btn-success,
  &.btn-info,
  &.btn-warning,
  &.btn-danger,
  &.btn-dark {
    color: $white !important;
  }

  &.btn-default {
    color: $text;
    background-color: $default;
    border-color: $default;
    &:hover,
    &:active {
      background-color: lighten($default, 6%);
      border-color: lighten($default, 6%);
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: darken($default, 6%);
      border-color: darken($default, 6%);
    }
  }
  &.btn-primary {
    background-color: $primary;
    border-color: $primary;
    &:hover,
    &:active {
      background-color: $blue-light;
      border-color: $blue-light;
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: $blue-dark !important;
      border-color: $blue-dark !important;
    }
  }
  &.btn-secondary {
    background-color: $secondary;
    border-color: $secondary;
    &:hover,
    &:active {
      background-color: lighten($secondary, 6%);
      border-color: lighten($secondary, 6%);
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: darken($secondary, 6%);
      border-color: darken($secondary, 6%);
    }
  }
  &.btn-success {
    background-color: $success;
    border-color: $success;
    &:hover,
    &:active {
      background-color: lighten($success, 6%);
      border-color: lighten($success, 6%);
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: darken($success, 6%);
      border-color: darken($success, 6%);
    }
  }
  &.btn-danger {
    background-color: $danger;
    border-color: $danger;
    &:hover,
    &:active {
      background-color: lighten($danger, 6%);
      border-color: lighten($danger, 6%);
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: darken($danger, 6%);
      border-color: darken($danger, 6%);
    }
  }
  &.btn-warning {
    background-color: $warning;
    border-color: $warning;
    &:hover,
    &:active {
      background-color: lighten($warning, 6%);
      border-color: lighten($warning, 6%);
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: darken($warning, 6%);
      border-color: darken($warning, 6%);
    }
  }
  &.btn-info {
    background-color: $info;
    border-color: $info;
    &:hover,
    &:active {
      background-color: lighten($info, 6%);
      border-color: lighten($info, 6%);
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: darken($info, 6%);
      border-color: darken($info, 6%);
    }
  }
  &.btn-light {
    background-color: $light;
    border-color: $light;
    &:hover,
    &:active {
      background-color: darken($light, 6%);
      border-color: darken($light, 6%);
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: darken($light, 10%);
      border-color: darken($light, 10%);
    }
  }
  &.btn-dark {
    background-color: $dark;
    border-color: $dark;
    &:hover,
    &:active {
      background-color: lighten($dark, 6%);
      border-color: lighten($dark, 6%);
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: darken($dark, 6%);
      border-color: darken($dark, 6%);
    }
  }

  &.btn-outline-default {
    color: $text;
    background-color: $white;
    border-color: $border;
    &:hover,
    &:active {
      background-color: $gray-2 !important;
      border-color: $gray-2;
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: $gray-2 !important;
      border-color: $gray-2;
    }
  }
  &.btn-outline-primary {
    border-color: $primary;
    color: $primary;
    &:hover,
    &:active {
      background-color: $blue-light !important;
      border-color: $blue-light;
      color: $white;
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: $blue-dark !important;
      border-color: $blue-dark;
      color: $white;
    }
  }
  &.btn-outline-secondary {
    border-color: $secondary;
    color: $secondary;
    &:hover,
    &:active {
      background-color: lighten($secondary, 6%) !important;
      border-color: lighten($secondary, 6%);
      color: $white;
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: darken($secondary, 6%) !important;
      border-color: darken($secondary, 6%);
      color: $white;
    }
  }
  &.btn-outline-success {
    border-color: $success;
    color: $success;
    &:hover,
    &:active {
      background-color: lighten($success, 6%) !important;
      border-color: lighten($success, 6%);
      color: $white;
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: darken($success, 6%) !important;
      border-color: darken($success, 6%);
      color: $white;
    }
  }
  &.btn-outline-danger {
    border-color: $danger;
    color: $danger;
    &:hover,
    &:active {
      background-color: lighten($danger, 6%) !important;
      border-color: lighten($danger, 6%);
      color: $white;
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: darken($danger, 6%) !important;
      border-color: darken($danger, 6%);
      color: $white;
    }
  }

  &.btn-outline-warning {
    border-color: $warning;
    color: $warning;
    &:hover,
    &:active {
      background-color: lighten($warning, 6%) !important;
      border-color: lighten($warning, 6%);
      color: $white;
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: darken($warning, 6%) !important;
      border-color: darken($warning, 6%);
      color: $white;
    }
  }
  &.btn-outline-info {
    border-color: $info;
    color: $info;
    &:hover,
    &:active {
      background-color: lighten($info, 6%) !important;
      border-color: lighten($info, 6%);
      color: $white;
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: darken($info, 6%) !important;
      border-color: darken($info, 6%);
      color: $white;
    }
  }
  &.btn-outline-light {
    border-color: $light;
    color: $text;
    &:hover,
    &:active {
      background-color: darken($light, 6%) !important;
      border-color: darken($light, 6%);
      color: $primary;
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: darken($light, 6%) !important;
      border-color: darken($light, 6%);
      color: $primary;
    }
  }
  &.btn-outline-dark {
    border-color: $dark;
    color: $dark;
    &:hover,
    &:active {
      background-color: lighten($dark, 6%) !important;
      border-color: lighten($dark, 6%);
      color: $white;
    }
    &:hover:active,
    &:focus,
    &.active {
      background-color: darken($dark, 6%) !important;
      border-color: darken($dark, 6%);
      color: $white;
    }
  }
}

// dark theme

[data-vb-theme="dark"] {
  .btn,
  .show > .btn {
    &.btn-light,
    &.btn-outline-default {
      background-color: $dark;
      border-color: $dark;
      color: $dark-gray-1;
      &:hover,
      &:active {
        background-color: lighten($dark, 4%) !important;
        border-color: lighten($dark, 4%) !important;
        color: $dark-gray-1;
      }
      &:hover:active,
      &:focus,
      &.active {
        background-color: lighten($dark, 6%) !important;
        border-color: lighten($dark, 6%) !important;
        color: $dark-gray-1;
      }
    }
    &.btn-dark {
      background-color: $light;
      border-color: $light;
      color: $dark-gray-4 !important;
      &:hover,
      &:active {
        background-color: darken($light, 6%);
        border-color: darken($light, 6%);
      }
      &:hover:active,
      &:focus,
      &.active {
        background-color: darken($light, 10%);
        border-color: darken($light, 10%);
      }
    }
    &.btn-outline-primary,
    &.btn-outline-secondary,
    &.btn-outline-success,
    &.btn-outline-danger,
    &.btn-outline-warning,
    &.btn-outline-info,
    &.btn-outline-light,
    &.btn-outline-dark {
      background: transparent;
    }
    &.btn-outline-light {
      border-color: $dark;
      color: $dark-gray-1;
      &:hover,
      &:active {
        background-color: lighten($dark, 4%) !important;
        border-color: lighten($dark, 4%);
      }
      &:hover:active,
      &:focus,
      &.active {
        background-color: darken($dark, 6%) !important;
        border-color: darken($dark, 6%);
      }
    }
    &.btn-outline-dark {
      border-color: $light;
      color: $light;
      &:hover,
      &:active {
        color: $dark-gray-4 !important;
        background-color: darken($light, 6%) !important;
        border-color: darken($light, 6%);
      }
      &:hover:active,
      &:focus,
      &.active {
        color: $dark-gray-4 !important;
        background-color: darken($light, 6%) !important;
        border-color: darken($light, 6%);
      }
    }
  }
}
