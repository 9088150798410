// Import mixins
@import "src/mixins.scss";

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-vb-theme="dark"] {
  .ant-empty {
    svg * {
      fill: $gray-5;
      stroke: $gray-6;
    }
  }
}
