// Import mixins
@import "src/mixins.scss";

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-vb-theme="dark"] {
  .ant-progress {
    &-inner {
      background: $dark-gray-4;
    }
  }
}
